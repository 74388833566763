<template>
    <v-card class="card-infobox" :width="380" color="white">
        <v-card-text>
            <div>
                <span 
                :class="ColorUtils.setClassColorText(dataset.equipmentType, dataset.equipmentStatus)" 
              >
                {{ vehicle.prefix }} - {{ vehicle.plate }}
              </span>
                <br />
                <span class="desc-label">Placa</span>
            </div>

            <div class="mt-2" v-if="!dataset.viewAproximation">
                <div v-if="vehicle.position.accurate" class="position-info accurate">
                    <v-icon color="#222D6C">mdi-map-marker-check</v-icon>
                    <span>Posição Precisa</span>
                </div>
                <div v-else class="position-info inaccurate">
                    <v-icon color="#222D6C">mdi-map-marker-alert-outline</v-icon>
                    <span>Posição Imprecisa</span>
                </div>
            </div>

            <div class="mt-2" v-if="!dataset.viewAproximation">
                <v-row>
                    <v-col>
                        <span class="subtitle-label">{{ vehicle.brand | enum(dataset.vehicleBrands, "id", "name") }}</span>
                        <br />
                        <span class="desc-label">Marca</span>
                    </v-col>
                    <v-col>
                        <span class="subtitle-label">{{ vehicle.client.name }}</span>
                        <br />
                        <span class="desc-label">Cliente</span>
                    </v-col>
                </v-row>
            </div>

            <div v-if="dataset.equipmentType === 5 && !dataset.viewAproximation" >
                <div class="mt-2">
                    <v-row>
                        <v-col>
                            <span class="subtitle-label">{{ vehicle.equipment.serial }}</span>
                            <br />
                            <span class="desc-label">Equipamento</span>
                        </v-col>
                        <v-col>
                            <span class="subtitle-label">{{ dataset.vehicle.battery }}</span>
                            <br />
                            <span class="desc-label">Bateria</span>
                        </v-col>
                    </v-row>
                </div>
    
                <div class="mt-2">
                    <v-row>
                        <v-col>
                            <span class="subtitle-label">{{ this.formatDate(dataset.vehicle.date) }} </span>
                            <br />
                            <span class="desc-label">Data ({{  this.calculateTimeDifference(dataset.vehicle.date) }})</span>
                        </v-col>
                        <v-col>
                            <span class="subtitle-label">{{ this.formatTime(dataset.vehicle.date) }}</span>
                            <br />
                            <span class="desc-label">Hora</span>
                        </v-col>
                    </v-row>
                </div>
            </div>

            <div v-if="dataset.equipmentType === 4 && !dataset.viewAproximation">
                <div class="mt-2">
                    <v-row>
                        <v-col>
                            <span class="subtitle-label">{{ dataset.vehicle.equipment.serial }}</span>
                            <br />
                            <span class="desc-label">Equipamento</span>
                        </v-col>

                        <!-- TODO: O que seria tempo bloqueado? -->
                        <v-col>
                            <span class="subtitle-label">{{ this.formatTime(dataset.vehicle.date) }}</span>
                            <br />
                            <span class="desc-label">Tempo Bloqueado</span>
                        </v-col>
                    </v-row>
                </div>
            </div>
            <div class="mt-2" v-if="!dataset.viewAproximation">
                <v-row>
                    <v-col>
                        <span class="subtitle-label-sm">{{ address ? address : '...' }}</span>
                        <br />
                        <span class="desc-label">Endereço</span>
                    </v-col>
                    <v-col style="max-width: 180px;">
                        <span class="subtitle-label-sm">{{ vehicle.position.lat.toFixed(8) }}</span>
                        <br />  
                        <span class="subtitle-label-sm">{{ vehicle.position.lng.toFixed(8) }}</span>
                        <br />  
                        <span class="desc-label">Coordenadas</span>
                        <v-btn small icon @click="copy" class="btn-copy" >
                            <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>

            <div class="mt-2" v-if="trackingType == '3' && !dataset.viewAproximation && isVehicleRssiValid">
                <v-row>
                    <v-col>
                        <span class="subtitle-label">{{ dataset.vehicle.rssi }} dB</span>
                        <br />
                        <span class="desc-label">RSSI</span>
                    </v-col>
                    <v-col>
                        <span class="subtitle-label">{{ dataset.vehicle.snr }} dB</span>
                        <br />
                        <span class="desc-label">SNR</span>
                    </v-col>
                </v-row>
            </div>
        </v-card-text>

        <div class="mt-2 alert" v-if="setShowAlert(dataset.equipmentType, dataset.equipmentStatus) && trackingType != '3' && !dataset.viewAproximation">
            <div class="lightImportant px-6">
                <v-row align="center">
                    <v-col class="px-4" sm="1">
                        <v-icon color="important" size="27" left>
                            mdi-information-outline
                        </v-icon>
                    </v-col>
                    <v-col class="ml-2">
                        <span color="important" class="position-info-text">
                            Antes de adormecer o dispositivo certifique-se que o veículo não esteja com um
                            sinistro!
                        </span>
                    </v-col>
                </v-row>
            </div>
        </div>

        <v-card-text v-if="dataset.equipmentType === 5 && trackingType != '3' && !dataset.viewAproximation">
            <div class="button-group box-vehicle">
                <!-- <v-btn :color="setColorButton(dataset.equipmentType, dataset.equipmentStatus)" @click="handleModal(vehicle)" disabled>Bloquear</v-btn> -->
                <v-btn translate="no" :color="ColorUtils.setColorButton(dataset.equipmentType, dataset.equipmentStatus)" @click="handleModal(vehicle)"> {{setInfoButton(dataset.equipmentType, dataset.equipmentStatus)}}</v-btn>
                <v-spacer></v-spacer>
                <v-btn translate="no" :color="ColorUtils.setColorButton(dataset.equipmentType, dataset.equipmentStatus)" outlined @click="viewItem(vehicle)">Outra Ação</v-btn>
            </div>
        </v-card-text>


        <v-card-text v-if="dataset.equipmentType === 4 && trackingType != '3' && !dataset.viewAproximation">
            <div class="button-group box-vehicle">
                <v-btn translate="no" :color="ColorUtils.setColorButton(dataset.equipmentType, dataset.equipmentStatus)" @click="handleModal(vehicle)">{{setInfoButton(dataset.equipmentType, dataset.equipmentStatus)}}</v-btn>
                <v-spacer></v-spacer>
                <v-btn translate="no" :color="ColorUtils.setColorButton(dataset.equipmentType, dataset.equipmentStatus)" outlined @click="viewItem(vehicle)">Outra Ação</v-btn>
            </div>
        </v-card-text>

        <v-card-text v-if="trackingType == '3' && !dataset.viewAproximation">
            <v-row>
                <v-col>
                    <v-btn translate="no" class="action-button" color="primary" @click="handleViewAproximation">Histórico Aproximação</v-btn>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
        </v-card-text>

        <v-card-text v-if="dataset.viewAproximation && isVehicleRssiValid">
            <SignalStrengthIndicator :rssi="dataset.vehicle.rssi" :onReturn="handleViewAproximation" :viewReturn="true" :minRssi="dataset.minRssi" :maxRssi="dataset.maxRssi" />
        </v-card-text>
    </v-card>
</template>

<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from "axios";
import vehicleBrands from "@/common/data/vehicle-brands.data";
import moment from "moment";
import { eventHub } from '@/main';
import ColorUtils from "@/common/util/ColorUtils";
import SignalStrengthIndicator from './SignalStrengthIndicator.vue';


Vue.use(VueLodash)

export default {
    name: "VehicleInfoBox",

    props: {
        vehicles: Array,
        vehicle: null,
        trackingType: String,
        setVehicle: Function
    },

    components: {
        SignalStrengthIndicator
    },

    watch: {
        vehicles: {
            handler: function (val) {
                const selectedVehicle = val.find(vehicle => vehicle.id === this.vehicle.id);

                if (selectedVehicle) {
                    
                    this.dataset.vehicle = selectedVehicle;
                    this.getAdrress( selectedVehicle.plate, selectedVehicle.position.lat, selectedVehicle.position.lng);
                }
            },
            deep: true
        },
        vehicle: {
            handler: function (val) {
                this.dataset.equipmentStatus = val.equipment.status;
            },
            deep: true
        }
    },

    data: () => ({
        ColorUtils,
        address: null,
        dataset: {
            minRssi: -48,
            maxRssi: -120,
            equipmentType: null,
            equipmentStatus: null,
            viewAproximation: false,
            vehicleBrands: vehicleBrands,
            vehicle: null,
        },
    }),

    created() {
        this.loadRssi();
    },
    mounted() {
        this.dataset.vehicle = this.vehicle;

        this.dataset.equipmentStatus = this.vehicle.equipment.status;
        this.dataset.equipmentType = this.vehicle.equipment.model.type;

        this.address = null;
        this.getAdrress(this.vehicle.plate, this.vehicle.position.lat, this.vehicle.position.lng);
    },

    computed: {
        isVehicleRssiValid() {
            return this.dataset.vehicle && this.dataset.vehicle.rssi !== undefined;
        }
    },
    methods: {
        handleModal(item) {

            if(item.equipment.status === 3 && item.equipment.model.type === 4) {
                eventHub.$emit("VEHICLE-UNBLOCK-CHANGE", true);
                this.setVehicle(item);
            }

            if((item.equipment.status === 1 || item.equipment.status == 2 ) && item.equipment.model.type === 5) {
                eventHub.$emit("VEHICLE-SLEEP-CHANGE", true);
                this.setVehicle(item);
            }

            
            if( item.equipment.status === 4 && item.equipment.model.type === 5) {
                eventHub.$emit("VEHICLE-WAKEUP-CHANGE", true);
                this.setVehicle(item);
            }

            if((item.equipment.status === 1 || item.equipment.status == 2 ) && item.equipment.model.type === 4) {
                eventHub.$emit("VEHICLE-BLOCK-CHANGE", true);
                this.setVehicle(item);
            }
        },
        
        viewItem(item){
            this.$router.push(`/vehicles/${item.id}`);
        },

        formatDate(dateString) {
            return moment(dateString).format('DD/MM/YYYY');
        },

        formatTime(dateString) {
            return moment(dateString).format('HH:mm:ss');
        },

        calculateTimeDifference(dateString) {
            const now = moment();
            const vehicleDate = moment(dateString);
            const diffMinutes = now.diff(vehicleDate, 'minutes');
            const diffHours = now.diff(vehicleDate, 'hours');
            const diffDays = now.diff(vehicleDate, 'days');
            const diffWeeks = Math.floor(diffDays / 7);
            const diffMonths = now.diff(vehicleDate, 'months');
            const diffYears = now.diff(vehicleDate, 'years');

            if (diffMinutes < 60) {
                return `há ${diffMinutes} minuto${diffMinutes !== 1 ? 's' : ''}`;
            } else if (diffHours < 24) {
                return `há ${diffHours} hora${diffHours !== 1 ? 's' : ''}`;
            } else if (diffDays < 7) {
                return `há ${diffDays} dia${diffDays !== 1 ? 's' : ''}`;
            } else if (diffWeeks < 4) {
                return `há ${diffWeeks} semana${diffWeeks !== 1 ? 's' : ''}`;
            } else if (diffMonths < 1) {
                const additionalDays = diffDays % 30;
                return `há ${additionalDays} dia${additionalDays !== 1 ? 's' : ''}`;
            } else if (diffMonths < 12) {
                return `há ${diffMonths} mês${diffMonths !== 1 ? 'es' : ''}`;
            } else {
                return `há ${diffYears} ano${diffYears !== 1 ? 's' : ''}`;
            }
        },

        copy() {
            const coordenadas = `${this.vehicle.position.lat}, ${this.vehicle.position.lng}`;
            if (navigator.clipboard) {
                navigator.clipboard.writeText(coordenadas).then(() => {
                    this.$dialog.notify.info('Copiado para a área de transferência.', { position: 'top-right', timeout: 3000 });
                }).catch(err => {
                    console.log(err)
                    this.$dialog.notify.error('Erro ao copiar coordenadas.', { position: 'top-right', timeout: 3000 });
                });
            } else {
                this.$dialog.notify.error('A API de área de transferência não está disponível.', { position: 'top-right', timeout: 3000 });
            }
        },

        async loadRssi() {
            const response = await axios.get(`/equipments/1/rssi`);
            if(response.data.data) {
                this.dataset.minRssi = response.data.data.min;
                this.dataset.maxRssi = response.data.data.max;
            }
        },

        async getAdrress(plate, lat, lng) {
            const storedData = JSON.parse(localStorage.getItem('vehicleData')) || [];
            const existingEntry = storedData.find(entry => entry.lat === lat && entry.lng === lng);

            if (existingEntry) {
                this.address = existingEntry.address;
            } else {
                const geocoder = new google.maps.Geocoder();
                geocoder.geocode({ location: { lat, lng } }, (results, status) => {
                    if (status === 'OK') {
                        if (results[0]) {
                            const address = results[0].formatted_address;
                            this.address = address;

                            const newEntry = { plate, lat, lng, address };
                            const updatedData = storedData.filter(entry => entry.plate !== plate);
                            updatedData.push(newEntry);
                            localStorage.setItem('vehicleData', JSON.stringify(updatedData));
                        } else {
                            console.log('Nenhum endereço encontrado');
                        }
                    } else {
                        console.error('Geocoder falhou devido a: ' + status);
                    }
                });
            }
        },



        setShowAlert(type, status) {
            if(type === 5) {
                if(status === 1 || status === 2 || status === 3) {
                    return true;
                } else {
                    return false;
                }
            }
        },


        setInfoButton(type, status) {
            if(type === 5) {
                switch (status) {
                case 1:
                    return 'Dormir';
                case 2:
                    return 'Dormir';
                case 3:
                    return 'Dormir';
                case 5:
                    return 'Acordando...';
                case 6:
                    return 'Aguardando...';
                default:
                    return 'Acordar';
                }
            }

            if(type === 4) {
                switch (status) {
                case 1:
                    return 'Bloquear';
                case 2:
                    return 'Bloquear';
                case 3:
                    return 'Desbloquear';
                case 8:
                    return 'Desbloqueando';
                case 7:
                    return 'Bloqueando';
                default:
                    return 'Bloquear';
            }
            }
        },

        handleViewAproximation() {
            // verificar se o gateway foi selecionado

            const gateway = localStorage.getItem('gateway');
            if(!gateway) {
                this.$dialog.notify.error('Selecione um gateway para visualizar o histórico de aproximação.', { position: 'top-right', timeout: 3000 });
                return;
            }
            this.dataset.viewAproximation = !this.dataset.viewAproximation;
        }

    },
};
</script>

<style lang="scss">
.card-infobox {

    .title-label {
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
    }

    .subtitle-label {
        color: var(--v-primary-base);
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
    }

    .subtitle-label-sm {
        color: var(--v-primary-base);
        font-family: Montserrat;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
    }

    .desc-label {
        color: var(--v-mediumGray-base);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        /* 380% */
    }

}
.alert{
    margin: 20px 0;
}

.position-info-text {
    color: var(--v-important-base);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
}

.position-info {
    display: flex;
    padding: 7px;
    margin: 20px 0;
    background-color: #f3f3f3;
    align-items: center;
    gap: 8px;
}

.position-info span {
    font-weight: 600;
    font-size: 15px;
    color:#222D6C;
}

.inaccurate{
    width: 190px;
}
.accurate{
    width: 170px;
}

.box-vehicle > button{
    width: 49%;
}

.btn-copy{
    top: -3px;
    margin-left: 10px;
    position: relative;
}

.btn-copy--active:hover{
    opacity: none;
}

.btn-primary {
    color: var(--v-white-base);
    background-color: var(--v-primary-base);
}

.btn-secondary {
    color: var(--v-white-base);
    background-color: var(--v-secondary-base);
}
</style>