<template>
    <svg :style="{ transform: getTransform, width: width + 'px', height: height + 'px' }" viewBox="0 0 498.8 507.9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g id="Layer_1">
          <g>
            <path :fill="color" d="M321.2,5L102.3,73.6c-2,.9-3.8,1.9-5.8,4.1-1.4,1.7-2.1,5-2.2,8.5,0,62.9,0,140.1,0,203.1L0,209.9v62.5c.1,4.3-.5,10.4,2.6,17.6,3.1,5.8,9.4,12.2,18.5,21.2,71.5,61.2,143,122.4,214.4,183.5,6.5,3.7,14,9.8,27.6,12.1,17.1,2.8,34.1-1.1,42.1-3.3,58.8-18.5,117.7-37.1,176.5-55.6,2.9-1.2,11.8-5.9,14.7-12.1,2.5-3.2,2.5-20.2,2.1-22.4v-56.5l-16.5-64.4-57.7-210c-1.3-3.8-3.3-8.6-6.6-13.7-4.9-7.5-10.5-12.4-14.6-15.4-15-12.9-30-25.9-45-38.8-1.9-2.2-7.4-8.1-16.8-10.6-9.6-2.6-17.5-.1-20.3.9Z"/>
            <rect fill="#fff" x="278.6" y="438.8" width="17.9" height="54.3" rx="3.9" ry="3.9"/>
            <path fill="#fff" d="M487.5,432.3l-2.9.9c-1.5.4-2.2-.2-2.2-2.1v-47.3c0-1.9,0-3.6,2.2-4.6l2.9-1.2c2.5-.9,3.5,1.6,3.5,3.5v47.3c0,1.9-1.6,2.9-3.5,3.5Z"/>
            <path fill="#fff" d="M486.7,332.4l-38-140.7c-1.5,2.3-3.8,5.2-7.1,7.9-4.8,4.1-9.7,6.1-12.8,7.1-58.7,18.5-117.4,36.9-176,55.4-6.6,1.2-18.2,2.5-32-.6-13.4-3.1-23-8.9-28.5-12.8-16.9-14.4-33.8-28.8-50.7-43.2-1.9-2.3-4.5-3.8-6.4-3.2s-3,4.8-2,11.1c1.4,11.6,3.2,25.9,10.8,42.6,4.3,9.5,9.3,17.2,13.9,23.2,23.1,30.9,46.2,61.8,69.3,92.7,3.9,5.2,16.1,20,37.9,26.3,16.7,4.8,30.9,2.3,37.5.6,53.1-16.2,106.2-32.4,159.3-48.5,3.5-.8,9.9-2.7,16.1-7.7,4.3-3.5,7-7.2,8.6-9.9Z"/>
            <path :fill="color" d="M102.4,304L0,209.7l102.4-38.6v132.9ZM12.9,212.2l82.7,76.1v-107.3s-82.7,31.2-82.7,31.2Z"/>
          </g>
        </g>
      </g>
    </svg>
  </template>
  
  <script>
  export default {
    name: 'HorseIcon',
    props: {
      rotation: {
        type: Number,
        default: 0
      },
      color: {
        type: String,
        default: '#222D6C'
      },
      width: {
        type: Number,
        default: 30
      },
      height: {
        type: Number,
        default: 30
      }
    },
    computed: {
      getTransform() {
        const baseRotation = this.rotation;
        const flip = false;
        return flip ? `rotate(${baseRotation}deg) scaleX(-1)` : `rotate(${baseRotation}deg)`;
      }
    }
  }
  </script>