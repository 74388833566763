<template>
    <v-navigation-drawer v-model="dataset.opened" absolute bottom temporary right hide-overlay stateless :width="440">
        <div class="px-6">
            <v-row class="mt-2" align="center" justify="center">
                <v-col>
                    <span class="list-vehicle-title">Veículos</span>
                </v-col>
                <v-spacer></v-spacer>
                <v-col align="right" class="mt-2">
                    <v-btn icon elevation="0" @click="dataset.opened = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-col>
            </v-row>
            <v-row class="container-filters-II">
                <v-col>
                    <v-text-field id="info" label="Busque por cliente, placa ou prefixo" placeholder="Busque por cliente, placa ou prefixo" append-icon="mdi-magnify"
                        append-icon-color="primary" v-on:keyup="debounce" v-model="dataset.filter.info" hide-details
                        outlined>
                        <template v-slot:append>
                            <v-icon v-if="dataset.filter.info != null" @click="handleClearInfo" class="mr-2">mdi-close</v-icon>
                            <v-icon v-else class="mr-2">mdi-magnify</v-icon>
                        </template>
                    </v-text-field>

                </v-col>
            </v-row>
            <v-row class="container-filters-II">
                <v-col>
                    <v-select id="status" v-model="dataset.filter.status" :items="dataset.status" label="Status"
                        item-value="id" item-text="name" @change="handleStatusChange"  outlined hide-details="auto">
                    </v-select>
                </v-col>
            </v-row>
            <!-- <v-row>
                <v-col>
                    <v-select id="event" v-model="dataset.filter.event" :items="dataset.events" label="Eventos Diversos"
                        item-value="id" item-text="name" outlined hide-details="auto">
                    </v-select>
                </v-col>
            </v-row> -->
            <v-row>
                <v-col>
                    <template v-if="!dataset.loading && dataset.vehicles.length">
                        <v-card class="blocked-vehicle-card px-2 my-2" @click="openInfoWindow(item)" outlined v-for="(item, index) in dataset.vehicles"
                            v-bind:key="index">
                            <v-card-text>
                                <v-row align="center">
                                    <v-col cols="2" sm="2">
                                        <component :class="item.icon == 7 ? 'icon-size-truck-II' : 'icon-size-II'" :is="getIconComponent(item)" :color="ColorUtils.getColor(item)"></component>
                                    </v-col>
                                    <v-col cols="6" sm="6">
                                        <v-row no-gutters>
                                            <v-col>
                                                <div 
                                                    class="blocked-vehicle-card-title" 
                                                    :style="{ color: ColorUtils.setColorText(item.equipment.model.type, item.equipment.status)}"
                                                >
                                                    {{ item.plate }}
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col>
                                                <div class="blocked-vehicle-card-subtitle">{{ item.client.name }}</div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="4" sm="4" class="custom-col-flag">
                                        <div :class="ColorUtils.getTemplateFlag(item.equipment.model.type, item.equipment.status)">
                                            <span>{{ getEquipmentTypeName(item.equipment.model.type) }}</span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </template>
                    
                    <template v-if="!dataset.loading && !dataset.vehicles.length">
                        <div align="center">
                            <span color="gray">Nenhum veículo encontrado</span>
                        </div>
                    </template>
                    <template v-if="dataset.loading">
                        <div>
                            <v-progress-linear indeterminate color="#222D6C"></v-progress-linear>
                        </div>
                    </template>
                </v-col>
            </v-row>
        </div>
    </v-navigation-drawer>
</template>

<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';
import object from "@/common/util/object"
import string from "@/common/util/string"

import ColorUtils from "@/common/util/ColorUtils";
import CarIcon from '@/components/icons/CarIcon2d.vue';
import BusIcon from '@/components/icons/BusIcon2d.vue';
import HatchIcon from '@/components/icons/HatchIcon2d.vue';
import MotorcycleIcon from '@/components/icons/MotorcycleIcon2d.vue';
import PickupIcon from '@/components/icons/PickupIcon2d.vue';
import TruckIcon from '@/components/icons/TrcukIcon2d.vue';
import VanIcon from '@/components/icons/VanIcon.vue';
import CartIcon from "@/components/icons/CartIcon.vue";
import HorseIcon from "@/components/icons/HorseIcon.vue";


import { eventHub } from '@/main'

import vehicleIcons from "@/common/data/vehicle-icons.data"
import trackingStatus from "@/common/data/tracking-status.data"

Vue.use(VueLodash)

export default {
    name: "VehicleFilter",

    props: {
        openInfoWindow: Function
    },
    
    data: () => ({
        ColorUtils,
        dataset: {
            opened: false,
            loading: false,
            vehicleIcons: vehicleIcons,
            filter: {
                status: null,
                info: null
            },
            vehicles: [],
            status: [{ id: 0, name: "Todos" }, ...trackingStatus],
            events: []
        }
    }),

    created() {
        eventHub.$on("VEHICLE-FILTER-CHANGE", (value) => {
            this.dataset.opened = value;
        });

        const info = localStorage.getItem('info');
        if (info) {
            this.dataset.filter.info = info;
            this.$emit('update-filter-info', info);

            this.load();
        }

    },

    methods: {

        debounce: _.debounce(function () {
            this.load();
        }, 500),

        handleStatusChange() {
            this.debounce();
        },

        async load() {

            let search = Object.fromEntries(Object.entries(this.dataset.filter).filter(([_, v]) => v))

            search = !object.isEmpty(search) ? search : null;

            // if (!search) return;

            this.dataset.loading = true;

            // var limit = this.dataList.options.itemsPerPage;
            // var start = (this.dataList.options.page - 1) * limit;

            if(search){
                if(search.status ){
                    if(search.status == 1){
                        search.status = 'acordado';
                    }else{
                        search.status = 'bloqueado';
                    }
                }
            }

            setTimeout(async ()=>{
                try {
                    const result = await axios.get('/vehicles/broadcaster', { params: { start: 0, limit: 10000, search: search } });

                    this.dataset.vehicles = result.data.data;

                    this.resolveVehicles();
                } catch (error) {
                    console.log('error ', error)
                    this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
                } finally {
                    this.dataset.loading = false;
                }
            }, 1000)

        },

        getIconComponent(vehicle) {
            const iconComponents = {
                'car.svg': CarIcon,
                'bus.svg': BusIcon,
                'hatch.svg': HatchIcon,
                'motorcycle.svg': MotorcycleIcon,
                'pickup.svg': PickupIcon,
                'truck.svg': TruckIcon,
                'van.svg': VanIcon,
                'cart.svg': CartIcon,
                'horse.svg': HorseIcon
            };
        return iconComponents[vehicle.customIcon] || null;
        },

        resolveVehicles() {
            for (let item of this.dataset.vehicles) {
                const icon = this.getAssetsByIcon(item.icon);
                // const svg = this.$image.customSvgColor(`!!raw-loader!@/assets/images/vehicle/icons/${icon}`, '[carcolor]', '#FB3A04')

                item.customIcon = icon;
            }
        },

        getAssetsByIcon(icon) {
            return this.dataset.vehicleIcons.find((x) => x.id === icon).assets
        },

        openDetails(item){
            console.log(item);
        },

        getEquipmentTypeName(type) {
            if(type == 5){
                return 'Mac Lora'
            }

                if(type == 4){
                    return 'Mac 4g'
            }
        },

        handleClearInfo() {
            this.dataset.filter.info = null;
            localStorage.removeItem('info');
            this.$emit('update-filter-info', null);
            this.$emit('update-filter-serial', null);

            this.dataset.vehicles = [];
            // this.$emit('update-vehicles', []);
        },
    },
};
</script>

<style lang="scss">

.theme--light.v-input input, .theme--light.v-input textarea, .v-select__selection--comma  {
    color: #222D6C !important;
    font-weight: 500 !important;
}
.card-safe-area-infobox {

    .title-label {
        font-family: Montserrat;
        font-size: 28px;
        font-style: normal;
        text-transform: uppercase;
        font-weight: 700;
    }

    .subtitle-label {
        color: var(--v-primary-base);
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
    }

    .desc-label {
        color: var(--v-mediumGray-base);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        /* 380% */
    }

    .add-info-box {
        background: var(--v-secondary-base) !important;
        width: 45px;
        height: 45px;
    }
}


.icon-size-truck-II{
    position: relative;
    left: -5px;
    width: 50px !important;
}

.icon-size-II{
    position: relative;
    top: 5px;
    left: -3px;
    width: 70px !important;
}

.list-vehicle-title{
    font-size: 26px !important;
    font-weight: 800 !important;
}

.container-filters-II{
    margin-top: -10px !important;
}

.custom-col-flag{
    display: flex;
    justify-content: flex-end !important;
}

.equipament-blue-I,
.equipament-red-I,
.equipament-yellow {
    padding: 5px;
    width: 100% !important;
    border-radius: 5px;
    display: flex;  
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    justify-content: center;
}

.equipament-blue-I{
    color: var(--v-primary-base);
    background-color: rgba(34, 45, 108, 0.07);
}

.equipament-red-I{
    color: var(--v-important-base);
    background-color: rgba(251, 58, 4, 0.07);
}

.equipament-yellow{
    color: var(--v-yellow-base);
    background-color: rgba(255, 183, 3, 0.07);
}
</style>