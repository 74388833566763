<template>
  <svg :style="{ transform: getTransform }" viewBox="0 0 216 206" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path d="M175.091 139.923L126.444 177.04C123.103 179.586 118.499 179.813 116.723 177.483L38.6031 74.9901C36.8209 72.6518 38.2609 68.2725 41.6073 65.7345L90.1218 28.7569C93.4622 26.2109 98.0666 25.9832 99.8427 28.3134L177.962 130.806C179.739 133.137 178.437 137.385 175.091 139.923Z" :fill="color"/>
      <path d="M171.871 135.236L170.375 136.375C169.779 136.83 168.918 136.707 168.457 136.102L164.432 130.821C163.971 130.217 164.087 129.362 164.683 128.907L166.179 127.767C166.775 127.313 167.636 127.436 168.097 128.041L172.122 133.322C172.582 133.926 172.467 134.781 171.871 135.236Z" fill="white"/>
      <path d="M123.957 171.567L122.462 172.706C121.865 173.161 121.004 173.038 120.544 172.433L116.519 167.152C116.058 166.548 116.173 165.693 116.77 165.238L118.265 164.099C118.861 163.644 119.722 163.767 120.183 164.372L124.208 169.653C124.669 170.257 124.554 171.112 123.957 171.567Z" fill="white"/>
      <path d="M143.474 108.157L103.859 138.187C102.094 139.533 99.5793 138.973 98.7362 137.025C95.1772 128.924 90.8875 120.211 85.7189 111.137C80.2605 101.567 74.6504 93.0687 69.2143 85.6066C68.155 84.1508 68.4989 82.0781 69.9941 80.9384L97.7576 59.8903C99.3403 58.684 101.553 58.9969 102.617 60.5747C107.348 67.6062 113.468 75.5529 121.319 83.6262C129.024 91.5587 136.687 97.8514 143.556 102.788C145.358 104.08 145.331 106.767 143.488 108.159L143.48 108.165L143.474 108.157Z" fill="white"/>
      <path d="M141.19 92.7725L98.7145 37.0445C98.278 36.4719 97.3338 36.9652 97.5472 37.6576C98.9567 41.9648 100.844 46.7503 103.373 51.8008C106.461 57.947 109.803 63.0572 112.881 67.1615C116.977 72.0738 121.808 77.3565 127.48 82.7026C131.832 86.829 136.134 90.4777 140.231 93.692C140.825 94.1574 141.647 93.355 141.192 92.7585L141.184 92.7646L141.19 92.7725Z" fill="white"/>
      <path d="M89.5465 131.682L47.283 76.2327C46.8405 75.6521 47.6043 74.8561 48.2195 75.2674C51.9252 77.7867 55.9673 80.8794 60.0906 84.6395C65.1648 89.2843 69.1985 93.8671 72.34 97.9229C75.9989 103.169 79.8059 109.219 83.4991 116.061C86.3057 121.277 88.6846 126.279 90.7013 131.003C90.9988 131.707 89.9932 132.285 89.5325 131.68L89.5465 131.682Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="216" height="206" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'HatchIcon2d',
  props: {
    rotation: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: '#222E6C',
    },
  },
  computed: {
    getTransform() {
      const baseRotation = this.rotation;
      const flip = false;
      return flip ? `rotate(${baseRotation}deg) scaleX(-1)` : `rotate(${baseRotation}deg)`;
    },
  },
}
</script>