<template>
  <v-container class=" fill-height grid-list-md text-xs-center container-login">
    <v-row justify="center" style="margin: 0px">
      <v-col cols="12" sm="" md="6" lg="8" justify="space-between" style="height: 640px;">
        <v-row>
          <img class="logo" src="@/assets/logo-pilgrim.svg"/>
        </v-row>
        <v-row style="padding: 100px 0px 30px 0px">
          <div class="text-h3 font-weight-bold" style="color: #222D6C !important;">{{viewTitle}}</div>
        </v-row>
        <v-row justify="center" style="height: 300px">
          <v-form ref="form" v-model="redefinitionForm.validForm" lazy-validation style="width: 100%" @submit.prevent="createNewPassword">
            <v-text-field placeholder="Nova senha" label="Nova senha" ref="newPassword" outlined
              :rules="[rules.required, rules.password]" :type="redefinitionForm.showNewPassword ? 'text' : 'password'"
              v-model="redefinitionForm.user.newPassword" @input="validatePassword" @click:append="redefinitionForm.showNewPassword = !redefinitionForm.showNewPassword"
              :append-icon="redefinitionForm.showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
              hide-details="auto" class="mb-4" id="newPassword"></v-text-field>

            <v-text-field placeholder="Confirmar nova senha" label="Confirmar nova senha" ref="confirmPassword" outlined
              :rules="[rules.required, validatePasswordsMatch]" :type="redefinitionForm.showConfirmPassword ? 'text' : 'password'"
              v-model="redefinitionForm.user.confirmPassword" @input="validatePassword" @click:append="redefinitionForm.showConfirmPassword = !redefinitionForm.showConfirmPassword"
              :append-icon="redefinitionForm.showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
              hide-details="auto" class="mb-4" id="confirmPassword"></v-text-field>

            <v-row justify="center">
              <v-col>
                <v-btn color="secondary" elevation="2" x-large style="height: 56px; margin-top: 10px"
                  :loading="redefinitionForm.loading" type="submit">Criar nova senha</v-btn>
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-col>
                <span class="redefinition-info">Sua nova senha precisa conter pelo menos 8 dígitos, ter letras maiúsculas e minúsculas, números e caracteres especiais.</span>
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-col>
                <a class="btn-forgot-password" @click="handleLogin()">Voltar ao Login &lt; </a>
              </v-col>
            </v-row>
          </v-form>
        </v-row>
        <v-row class="mt-10" style="height: 200px;">
          <img src="@/assets/logo-baitatec-gray.svg" :width="150" />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import axios from 'axios';
import router from '../router';

export default {
  name: "Redefinition",

  data: () => ({
    viewTitle: "Nova Senha",
    redefinitionForm: {
      validForm: true,
      showNewPassword: false,
      showConfirmPassword: false,
      loading: false,
      user: {
        newPassword: '',
        confirmPassword: ''
      },
    },
    rules: {
      required: v => !!v || "Campo obrigatório",
      password: v => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(v) || "A senha deve ter pelo menos 8 dígitos, letras maiúsculas e minúsculas, números e caracteres especiais"
    }
  }),

  methods: {
    validatePasswordsMatch() {
      return this.redefinitionForm.user.newPassword === this.redefinitionForm.user.confirmPassword || "As senhas não coincidem";
    },

    validatePassword() {
      this.$refs.form.validate();
    },

    async createNewPassword() {
      if (!this.$refs.form.validate()) return;

      this.redefinitionForm.loading = true;

      try {
        const token = this.$route.params.token;
        await axios.patch('/password-redefinition', {
          token: token,
          password: this.redefinitionForm.user.newPassword,
          operation: 'forgot'
        });

        this.$dialog.notify.success('Senha criada com sucesso', { position: 'top-right', timeout: 5000 });
        setTimeout(() => {
          router.push("/login");
        }, 4000);
      } catch (error) {
        this.$dialog.notify.error('Erro ao criar nova senha', { position: 'top-right', timeout: 5000 });
      } finally {
        this.redefinitionForm.loading = false;
      }
    },

    handleLogin() {
      router.push("/login");
    }
  }
};
</script>

<style scoped lang="scss">

.container-login{
  overflow: scroll !important;
  background-color: #F5F6FA;
  padding: 0 0 40px 0 !important;
}
.theme--light.v-input input, 
.theme--light.v-input textarea, 
.v-select__selection--comma {
  color: #222D6C !important;
  font-weight: 500 !important;
}

  .logo {
    height: 80px;  
}



.btn-forgot-password {
  font-size: 14px;
  color: #9fa1a4;
  font-weight: 400;
}

.redefinition-info{
  color: #9fa1a4;
}

.btn-login {
  text-transform: none !important;
  letter-spacing: 1px;
  font-weight: 400;
}


@media screen and (max-width: 960px) {
  .logo {
    height: 60px;
  }

  .v-btn {
    width: 100% !important;
  }

  .container {
    padding: 50px !important;
  }
}

</style>