<template>
    <svg :style="{ transform: getTransform, width: width + 'px', height: height + 'px' }" viewBox="0 0 668.7 637" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g id="Layer_1">
          <g>
            <path :fill="color" d="M0,76.4v225.8c-.2,2.5-.1,6.7,1.6,11.5,1.3,3.5,3,6.1,4.4,7.9,38.8,33.1,77.5,66.1,116.3,99.2,2,1.7,3.9,3.3,8.9,3.4,10.4.2,16.1-12.1,26.8-23.5,6.3-6.7,12.1-5.6,29.1,8.9,85.5,73,170.9,146.1,256.4,219.1,3.2,1.9,8.9,7.7,15.4,8.2,4.7.4,10.6-.5,14.9-2,52.9-17.6,120.3-40.1,173.3-57.8,2.3-.5,11.7-2.9,17.5-11.9,3-4.6,3.8-9.2,4.1-11.9,1.2-49.4-1.3-102.1,0-151.5.2-1.9-.4-4.5-1.5-6.5-1.6-3-4.8-5.6-6.9-7.6C514.7,263.1,364.8,136.3,219.2,11.6c-5.2-3.3-10-7.6-20.9-9.9-14.5-3.2-25.4-1.1-32.1.5-47.2,15.2-98.9,30.1-146.1,45.2-2.3.7-12,2.7-16.6,10.7C-.4,64.8,0,74.1,0,76.4Z"/>
            <path fill="#2a3c84" d="M655.5,403.6l-172.9,53.7c-3.3,1.3-12.4,4.5-23.7,1.9-9.3-2.2-15.4-7.3-18-9.8C293.9,324.3,146.9,199.3,0,74.2c0-3.1.1-9.2,2.4-13.9,6.3-13,21.1-13.6,23.2-14.7C72.5,31.1,119.4,16.6,166.3,2.1c6.7-1.1,22.4-4.5,37.7,1.1,9.2,3.4,14.8,8.1,19.2,11.7,144.5,122.2,287.9,246.6,432.4,368.8,6.5,5.3,12.5,10.9,10.1,14.4-1.8,2.6-7.5,4.8-10.1,5.5Z"/>
          </g>
        </g>
      </g>
    </svg>
  </template>
  
  <script>
  export default {
    name: 'CartIcon',
    props: {
      rotation: {
        type: Number,
        default: 0
      },
      color: {
        type: String,
        default: '#222D6C'
      },
      width: {
        type: Number,
        default: 30
      },
      height: {
        type: Number,
        default: 30
      }
    },
    computed: {
      getTransform() {
        const baseRotation = this.rotation;
        const flip = false;
        return flip ? `rotate(${baseRotation}deg) scaleX(-1)` : `rotate(${baseRotation}deg)`;
      }
    }
  }
  </script>