<template>
    <v-container style="max-width: 1700px">

        <v-row>
            <v-col>
                <div class="font-weight-bold mt-10 title-page">Olá, {{ user ? user.name : "" }}!</div>
            </v-col>
        </v-row>

        <v-row class="mt-10" no-gutters>
            <v-col v-for="(item, index) in filteredReportCards" v-bind:key="index" cols="12" sm="2" :class="isClient() ? 'custom-col-client' : 'custom-col'">
                <v-sheet class="pr-2">
                    <v-card class="mx-auto report-card" prepend-icon="mdi-home" :color="item.color">
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <div class="d-sm-flex text-white">
                                        <div class="mr-2 report-card-image">
                                            <v-img :width="35" color="gray" :src="item.icon"></v-img>
                                        </div>
                                        <div class="text-white mb-0 report-card-value-text">{{ item.value }}</div>
                                    </div>
                                    <div class="report-card-text">
                                        {{ item.text }}
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-sheet>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" sm="7" md="7" lg="8" xl="9">
                <v-row>
                    <v-col>
                        <v-card>
                            <v-card-title>
                                <div class="section-title font-weight-bold">Equipamentos</div>
                            </v-card-title>
                            <v-card-text>
                                <v-data-table :headers="filteredHeaders" :items="dataset.equipments"
                                    no-data-text="Nenhum equipamento foi encontrado" loading-text="Carregando dados..."
                                    class="app-table hover-table" :footer-props="{
                                        itemsPerPageOptions: [10, 20, 30, 40, 50],
                                        itemsPerPageText: 'Registros por página',
                                    }">
                                    
                                    <template v-slot:[`item.serial`]="{ item }">
                                        <span class="list-item-title">{{ item.serial }}</span><br />
                                        <span class="list-item-subtitle">{{ item.model.name}}</span>
                                    </template>
    
                                    <template v-slot:[`item.stage`]="{ item }">
                                        <span class="list-item-title">{{ item.stage | enum(dataset.equipmentStages, "id", "name") }}</span>
                                    </template>
                                    
                                    <template v-slot:[`item.client`]="{ item }">
                                        <span class="list-item-title">{{ item.client && item.client.name ? item.client.name : '' }}</span>
                                    </template>
               
                                    <template v-slot:[`item.status`]="{}">
                                        <div class="tag-disconnected">
                                            <span class="text-disconnected">Desconectado</span>
                                        </div>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <!-- <v-col sm="1"></v-col> -->
            <v-col cols="12" sm="5" md="5" lg="4" xl="3">
                <v-card>
                    <v-card-title>
                        <div class="section-title font-weight-bold">Sinistros</div>

                        <v-spacer></v-spacer>
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn text v-bind="attrs" v-on="on">
                                    <v-icon left>mdi-filter-variant</v-icon>
                                    <span v-if="dataset.equipmentStatus">{{ dataset.equipmentStatus }}</span>
                                    <span v-else>Todos</span>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="filterVehicles()">
                                    <v-list-item-title>Todos</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="filterVehicles('bloqueado')">
                                    <v-list-item-title>Bloqueado</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="filterVehicles('acordado')">
                                    <v-list-item-title>Acordado</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-card-title>

                    <v-card-text>
                        <v-row class="container-filters-II">
                            <v-col>
                                <v-text-field id="plate" :label="isClient() ? 'Busque por placa ou prefixo' : 'Busque por cliente, placa ou prefixo'" append-icon="mdi-magnify"
                                    append-icon-color="primary" v-model="dataset.filter.info" v-on:keyup="debounce" hide-details transition="scale-transition" outlined></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row v-if="dataset.loading">
                            <v-col>
                                <template >
                                    <v-progress-linear indeterminate color="#222D6C"></v-progress-linear>
                                </template>
                            </v-col>
                        </v-row>
    
                        <v-row class="info-not-vehicle" v-if="!dataset.loading && dataset.sinister.length == 0">
                            <v-col>
                                <template >
                                <v-alert type="error" dismissible icon="mdi-broadcast-off">
                                    <span>Nenhum sinistro encontrado</span>
                                </v-alert>
                                </template>
                            </v-col>
                        </v-row>
                        <v-row class="custon-row-sinisters">
                            <v-col cols="12">
                                <v-card class="vehicle-card px-2 my-2" outlined v-for="(item, index) in dataset.sinister"
                                    @click="handleViewMap(item)" v-bind:key="index">
                                    <v-card-text>
                                        <v-row align="center">
                                            <v-col cols="12" sm="2">
                                                <component :class="item.icon == 7 ? 'icon-size-truck-III' : 'icon-size-III'" :is="getIconComponent(item)" :color="ColorUtils.getColor(item)"></component>
                                            </v-col>
                                            <v-col cols="12" sm="5">
                                                <v-row no-gutters>
                                                    <v-col>
                                                        <div 
                                                            class="vehicle-card-title"
                                                            :style="{ color: ColorUtils.setColorText(item.equipment.model.type, item.equipment.status)}"
                                                        >
                                                        {{ item.plate }}
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters>
                                                    <v-col>
                                                        <div class="vehicle-card-subtitle">{{ item.client.name }}</div>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="1" sm="5" class="custom-col-flag">
                                                <div :class="ColorUtils.getTemplateFlag(item.equipment.model.type, item.equipment.status)">
                                                    <span>{{ getEquipmentTypeName(item.equipment.model.type) }}</span>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-row>
                        <v-col>
                            <v-pagination
                                v-model="currentPage"
                                :length="totalPages"
                                @input="onPageChange"
                            ></v-pagination>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import 'moment/locale/pt-br';
import user from "@/common/util/identify";
import vehicleIcons from "@/common/data/vehicle-icons.data"
import equipmentStages from "@/common/data/equipment-stages.data"

import Vue from "vue";
import _, { debounce } from 'lodash';
import VueLodash from 'vue-lodash'

Vue.use(VueLodash)


import CarIcon from '@/components/icons/CarIcon2d.vue';
import BusIcon from '@/components/icons/BusIcon2d.vue';
import HatchIcon from '@/components/icons/HatchIcon2d.vue';
import PickupIcon from '@/components/icons/PickupIcon2d.vue';
import TruckIcon from '@/components/icons/TrcukIcon2d.vue';
import VanIcon from '@/components/icons/VanIcon.vue';
import MotorcycleIcon from '@/components/icons/MotorcycleIcon2d.vue';
import CartIcon from '@/components/icons/CartIcon.vue';
import HorseIcon from '@/components/icons/HorseIcon.vue';

import ColorUtils from "@/common/util/ColorUtils";

import object from "@/common/util/object"
import axios from "axios"

export default {
    data: () => ({
        user: null,
        ColorUtils: ColorUtils,
        start: 0,
        limit: 5,
        currentPage: 1,
        itemsPerPage: 5,
        totalPages: 1,
        dataset: {
            filter: {
                info: null,
                sinister: true
            },
            clients: [],
            sinister: [],
            equipments: [],
            loading: false,
            equipmentStatus: null,
            vehicleIcons: vehicleIcons,
            equipmentStages: equipmentStages
        },
        headers: [
            { text: 'N. Série/Tipo', value: 'serial', sortable: true  },
            { text: 'Etapa', value: 'stage', sortable: true  },
            { text: 'Cliente', value: 'client.name', sortable: true  },
            { text: 'Tempo (MIN)', value: 'time', sortable: true  },
            { text: 'Status', value: 'status', sortable: false  }
        ],
        reportCards: [
            {
                key: "clients",
                text: "Clientes",
                icon: require('../assets/images/icons/account-group.svg'),
                color: "mediumGray",
                value: 0
            },
            {
                key: "equipments",
                text: "Equipamentos",
                icon: require('../assets/images/icons/broadcast.svg'),
                color: "primary",
                value: 0
            },
            {
                key: "disconnected",
                text: "Desconectados",
                icon: require("../assets/images/icons/broadcast-off.svg"),
                color: "secondary",
                value: 0
            },
            {
                key: "blocked",
                text: "Bloqueados",
                icon: require("../assets/images/icons/lock-alert-outline.svg"),
                color: "important",
                value: 0
            },

            {
                key: "awake",
                text: "Acordados",
                icon: require("../assets/images/icons/awake.svg"),
                color: "yellow",
                value: 0
            },
        ],
        blockedVehicles: [],
        data: []
    }),

    computed: {
        filteredHeaders() {
            return this.headers.filter(header => {
                if (header.value === 'client.name' && this.isClient()) {
                return false;
                }
                return true;
            });
        },

        filteredReportCards() {
            return this.reportCards.filter(card => {
                if (card.key === "clients" && this.isClient()) {
                return false;
                }
                return true;
            });
        }
    },

    mounted: function () {
        this.$root.title = "Home";
        this.$root.show({});

        this.user = this.$root.user;
        localStorage.removeItem('info');
    },

    created() {
        this.loadAwake();
        if(!this.isClient()){
            this.loadClients();
        }
        this.loadBlocked();
        this.loadVehicles();
        this.loadEquipments();
        this.loadDisconnected();
    },

    methods: {
        debounce: _.debounce(function() {
            this.loadVehicles();
        }, 500),

        async loadClients() {
            try {
                const result = await axios.get('/clients', { params: { limit: 1000000, start: 0 } });

                this.dataset.clients = result.data.data;

                this.reportCards[0].value = this.dataset.clients.length;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            }
        },

        async loadEquipments() {
            try {
                const result = await axios.get('/equipments', { params: { limit: 1000000, start: 0 } });

                this.reportCards[1].value = result.data.data.length;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            }
        },

        async loadDisconnected() {
            try {
                const result = await axios.get('/equipments/1/list-disconnecteds');

                this.dataset.equipments = result.data.data;
                this.reportCards[2].value = result.data.count;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            }
        },

        async loadAwake() {
            try {
                const result = await axios.get('/equipments/1/awakes');

                this.reportCards[4].value = result.data.data;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            }
        },

        async loadBlocked() {
            try {
                const result = await axios.get('/equipments/1/blockeds');

                this.reportCards[3].value = result.data.data;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            }
        },
        onPageChange(page) {
            this.currentPage = page;
            this.start = (page - 1) * this.itemsPerPage;
            this.loadVehicles();
        },

        
        async loadVehicles() {
            this.dataset.loading = true;
            let search = Object.fromEntries(Object.entries(this.dataset.filter).filter(([_, v]) => v));

            search = !object.isEmpty(search) ? search : null;

            if (this.dataset.equipmentStatus) {
                search.status = this.dataset.equipmentStatus;
            }

            try {
                const result = await axios.get('/vehicles/broadcaster', { params: { start: this.start, limit: this.limit, search } });
                this.dataset.sinister = result.data.data;

                this.totalPages = Math.ceil(result.data.count / this.limit);
                this.resolveVehicles();
            } catch (error) {
                console.log('error ', error);
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            }

            this.dataset.loading = false;
        },

        resolveVehicles() {
            for (let item of this.dataset.sinister) {
                const icon = this.getAssetsByIcon(item.icon);
                item.customIcon = icon;
            }
        },

        getAssetsByIcon(icon) {
            return this.dataset.vehicleIcons.find((x) => x.id === icon).assets
        },

        getIconComponent(vehicle) {
            const iconComponents = {
                'car.svg': CarIcon,
                'bus.svg': BusIcon,
                'hatch.svg': HatchIcon,
                'motorcycle.svg': MotorcycleIcon,
                'pickup.svg': PickupIcon,
                'truck.svg': TruckIcon,
                'van.svg': VanIcon,
                'cart.svg': CartIcon,
                'horse.svg': HorseIcon
            };
        return iconComponents[vehicle.customIcon] || null;
        },

        getEquipmentTypeName(type) {
            if(type == 5){
                return 'Mac Lora'
            }

            if(type == 4){
                return 'Mac 4g'
            }
        },

        handleViewMap(item) {
            const plate = item.plate;

            localStorage.setItem('info', plate);
            this.$router.push({ 
                path: '/tracking/1',
            });
        },

        filterVehicles(type) {
            if(type){
                this.dataset.equipmentStatus = type;
                this.loadVehicles();

            }else{
                this.dataset.equipmentStatus = null;
                this.loadVehicles();
            }
        },

        isClient() {
            const { client } = user.identify();
            return !!client;
        },
    }
};
</script>

<style lang="scss">
.report-card {
    .report-card-image {
        width: 32px;
        height: 32px;
        flex-shrink: 0;
        // opacity: 0.25;
    }

    .report-card-value-text {
        font-family: Montserrat;
        font-size: 40px;
        font-style: normal;
        font-weight: 300;
        line-height: 22px;
        letter-spacing: -0.06px;
        color: var(--v-white-base);
    }

    .report-card-text {
        color: var(--v-white-base);
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px;
    }
}

.title-page {
    color: var(--v-primary-base);
    font-family: Montserrat;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.blocked-vehicle-card {
    .blocked-vehicle-card-title {
        color: var(--v-important-base);
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
    }

    .blocked-vehicle-card-subtitle {
        color: var(--v-mediumGray-base);
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
    }
}

.custom-col {
    flex: 0 0 20%;
    max-width: 20%;
}

.custom-col-client{
    flex: 0 0 25%;
    max-width: 25%;
}

.tag-disconnected {
    text-align: center;
    color: var(--v-secondary-base);
    background-color: rgba(1, 188, 239, 0.07);
    padding: 4px 8px;
}   


.box-sinisters{
    padding: 5px 20px;
    background-color: #ffffff !important;


}

.v-card {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
}

.filter-selected {
    background-color: #f5f5f5;
    border-radius: 4px;
    padding: 4px 8px;
    color: #333;
    font-weight: bold;
    margin-left: 8px;
    font-size: 12px;
}

.custon-row-sinisters{
    max-height: 450px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 7px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: var(--v-primary-base);
        border-radius: 10px;
    }
}

.v-dialog{
    
    &::-webkit-scrollbar {
        width: 7px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #959595;
        border-radius: 10px;
    }
}

</style>
