<template>
  <svg :style="{ transform: getTransform }" viewBox="0 0 402 378" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path d="M340.614 295.062L295.387 336.78C292.292 339.634 287.487 339.455 284.646 336.375L60.697 93.589C57.8561 90.5092 58.0642 85.7054 61.1587 82.8509L106.386 41.1325C109.481 38.2781 114.286 38.4578 117.127 41.5377L341.076 284.323C343.916 287.403 343.708 292.207 340.614 295.062Z" :fill="color"/>
      <path d="M284.878 241.378L246.972 276.343C244.377 278.737 239.915 278.118 237.006 274.965L65.6647 89.211C62.756 86.0577 62.4987 81.5606 65.0934 79.1672L103 44.2018C105.594 41.8084 110.056 42.4273 112.965 45.5806L284.307 231.334C287.215 234.487 287.473 238.985 284.878 241.378Z" :fill="color"/>
      <path d="M293.214 331.18L291.846 332.441C291.302 332.942 290.455 332.908 289.946 332.357L285.505 327.542C284.997 326.991 285.031 326.143 285.575 325.641L286.942 324.38C287.486 323.879 288.334 323.913 288.842 324.464L293.283 329.279C293.792 329.83 293.757 330.678 293.214 331.18Z" fill="white"/>
      <path d="M337.037 290.756L335.67 292.017C335.126 292.519 334.278 292.485 333.77 291.934L329.329 287.119C328.82 286.568 328.854 285.72 329.398 285.218L330.765 283.957C331.309 283.455 332.157 283.49 332.666 284.041L337.107 288.855C337.615 289.407 337.581 290.254 337.037 290.756Z" fill="white"/>
      <path d="M287.957 316.056L320.262 286.258C320.784 285.776 323.665 282.956 323.7 278.407C323.736 274.183 321.293 271.431 320.757 270.85L299.982 248.328C300.407 248.862 303.082 252.367 302.174 256.973C301.561 260.069 299.68 261.967 298.945 262.645L266.713 292.376C265.959 292.99 263.705 294.661 260.373 294.795C256.121 294.964 253.269 292.492 252.69 291.952L273.498 314.51C274.035 315.049 277.296 318.259 281.685 318.209C284.921 318.177 287.176 316.655 287.971 316.057L287.957 316.056Z" fill="white"/>
      <path d="M112.612 45.198L111.87 45.8828L311.207 261.986L311.949 261.302L112.612 45.198Z" fill="white"/>
      <path d="M66.0545 88.1438L65.3121 88.8286L263.313 303.484L264.056 302.799L66.0545 88.1438Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="402" height="378" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
  
<script>
export default {
  name: 'BusIcon2d',
  props: {
    rotation: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: '#222E6C',
    },
  },
  computed: {
    getTransform() {
      const baseRotation = this.rotation;
      const flip = false;
      return flip ? `rotate(${baseRotation}deg) scaleX(-1)` : `rotate(${baseRotation}deg)`;
    },
  },
}
</script>