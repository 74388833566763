<template>
  <svg :style="{ transform: getTransform }" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_849_171)">
      <path d="M124.209 131.393C123.446 130.37 121.999 130.159 120.976 130.921C119.953 131.684 119.742 133.131 120.505 134.154L123.056 137.578C123.819 138.6 125.266 138.812 126.289 138.049C127.312 137.287 127.523 135.84 126.761 134.817L124.209 131.393Z" fill="black"/>
      <path d="M125.767 121.252L116.652 97.5762C116.898 98.2909 117.569 100.547 116.669 103.188C115.906 105.428 114.437 106.736 113.807 107.23C108.792 111.043 105.24 113.74 100.218 117.545C99.8132 117.872 97.5446 119.413 94.7347 119.375C93.1867 119.356 91.1797 118.37 90.8445 118.071C97.358 122.945 104.23 128.299 110.743 133.173C111.152 133.604 114.452 136.944 117.833 136.008C118.213 135.899 118.63 135.738 119.071 135.46L125.517 130.655C125.73 130.321 125.933 129.958 126.102 129.583C127.594 126.263 126.397 122.765 125.761 121.244L125.767 121.252Z" :fill="color"/>
      <path d="M116.652 97.576L87.967 59.09L61.7003 78.6675L90.9949 117.971C91.6116 118.397 92.4322 118.846 93.4475 119.087C97.0989 119.97 100.182 117.597 100.707 117.18C105.087 113.853 109.475 110.521 113.855 107.194C114.445 106.729 116.097 105.298 116.816 102.766C117.51 100.317 116.906 98.2847 116.66 97.57L116.652 97.576Z" :fill="color"/>
      <path d="M62.6042 43.2843L60.1267 45.1309L64.7044 51.2726L67.1819 49.426L62.6042 43.2843Z" :fill="color"/>
      <path d="M57.6287 41.7792C56.8663 40.7563 55.419 40.5451 54.3961 41.3075C53.3732 42.0699 53.162 43.5172 53.9244 44.5401L60.6116 53.5122C61.374 54.5351 62.8213 54.7463 63.8442 53.9838C64.8671 53.2214 65.0783 51.7742 64.3159 50.7512L57.6287 41.7792Z" fill="black"/>
      <path d="M56.4144 47.8975L53.9369 49.7441L58.5145 55.8859L60.9921 54.0393L56.4144 47.8975Z" :fill="color"/>
      <path d="M70.357 47.0595L55.2112 58.3482C52.138 60.6388 51.5036 64.9869 53.7941 68.06L71.286 91.5285C73.5765 94.6016 77.9246 95.2361 80.9978 92.9455L96.1436 81.6568C99.2168 79.3663 99.8512 75.0181 97.5607 71.945L80.0688 48.4766C77.7783 45.4034 73.4302 44.769 70.357 47.0595Z" :fill="color"/>
      <path d="M76.1253 72.3186L77.6327 71.1951C78.2341 70.7469 79.0937 70.8795 79.5478 71.4888L83.5159 76.8127C83.9701 77.4221 83.8456 78.2757 83.2442 78.7239L81.7369 79.8474C81.1355 80.2956 80.2759 80.1631 79.8217 79.5537L75.8536 74.2298C75.3995 73.6204 75.524 72.7668 76.1253 72.3186Z" fill="white"/>
      <path d="M86.5177 85.6257L88.0251 84.5022C88.6264 84.054 89.486 84.1866 89.9402 84.796L93.9083 90.1199C94.3624 90.7292 94.2379 91.5829 93.6366 92.0311L92.1292 93.1546C91.5278 93.6028 90.6682 93.4702 90.2141 92.8608L86.246 87.5369C85.7918 86.9276 85.9163 86.0739 86.5177 85.6257Z" :fill="color"/>
      <path d="M105.519 85.3328L106.463 86.5996C106.839 87.1047 106.799 87.7706 106.382 88.0814L84.4934 104.396C84.0765 104.707 83.435 104.549 83.0585 104.044L82.1143 102.777C81.7378 102.272 81.7778 101.606 82.1947 101.295L104.084 84.9805C104.501 84.6697 105.142 84.8276 105.519 85.3328Z" fill="white"/>
      <path d="M109.957 80.3781L111.039 81.8293C111.469 82.4066 111.325 83.2502 110.716 83.7044L78.9325 107.393C78.3231 107.848 77.4814 107.739 77.0512 107.162L75.9695 105.711C75.5392 105.133 75.6838 104.29 76.2931 103.835L108.076 80.1464C108.685 79.6923 109.527 79.8008 109.957 80.3781Z" :fill="color"/>
    </g>
    <defs>
      <clipPath id="clip0_849_171">
        <rect width="180" height="180" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'MotorcycleIcon2d',
  props: {
    rotation: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: '#222E6C',
    },
  },
  computed: {
    getTransform() {
      const baseRotation = this.rotation;
      const flip = false;
      return flip ? `rotate(${baseRotation}deg) scaleX(-1)` : `rotate(${baseRotation}deg)`;
    },
  },
}
</script>