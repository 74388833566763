<template>
  <svg :style="{ transform: getTransform }" viewBox="0 0 236 243" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_71_140)">
      <path d="M204.898 181.111L157.265 219.521C153.988 222.148 148.676 221.61 146.017 218.308L29.3556 73.4422C26.6963 70.1398 27.2913 64.8202 30.5687 62.1937L78.0785 23.9338C81.356 21.3073 86.6676 21.8446 89.327 25.1469L205.988 170.013C208.648 173.315 208.176 178.485 204.898 181.111Z" :fill="color"/>
      <path d="M201.56 176.52L200.096 177.699C199.512 178.169 198.648 178.069 198.171 177.477L194.006 172.305C193.53 171.713 193.622 170.856 194.206 170.385L195.67 169.206C196.255 168.736 197.118 168.836 197.595 169.428L201.76 174.599C202.237 175.191 202.144 176.049 201.56 176.52Z" fill="white"/>
      <path d="M154.634 214.117L153.169 215.296C152.585 215.766 151.721 215.666 151.245 215.074L147.08 209.902C146.603 209.311 146.696 208.453 147.28 207.982L148.744 206.803C149.328 206.333 150.192 206.433 150.669 207.025L154.834 212.197C155.31 212.789 155.218 213.646 154.634 214.117Z" fill="white"/>
      <path d="M167.432 143.978L128.634 175.055C126.905 176.448 124.377 175.955 123.482 174.03C119.708 166.028 115.187 157.432 109.778 148.499C104.066 139.079 98.2307 130.733 92.5973 123.419C91.4995 121.992 91.7879 119.91 93.2522 118.731L120.444 96.9493C121.994 95.7012 124.214 95.9548 125.32 97.5036C130.237 104.406 136.566 112.187 144.63 120.048C152.545 127.771 160.373 133.857 167.371 138.609C169.207 139.852 169.252 142.538 167.446 143.98L167.432 143.978Z" fill="white"/>
      <path d="M164.739 128.66L120.79 74.0856C120.338 73.5249 119.408 74.0433 119.64 74.7297C121.164 78.9977 123.178 83.7311 125.841 88.7123C129.092 94.7739 132.569 99.793 135.755 103.814C139.981 108.615 144.952 113.767 150.764 118.959C155.225 122.968 159.623 126.501 163.805 129.604C164.411 130.054 165.211 129.23 164.74 128.646L164.732 128.652L164.739 128.66Z" fill="white"/>
      <path d="M114.153 168.935L70.4234 114.633C69.9656 114.065 70.7079 113.249 71.3339 113.643C75.1055 116.063 79.2287 119.046 83.451 122.695C88.6474 127.203 92.802 131.676 96.0508 135.647C99.8484 140.793 103.816 146.74 107.69 153.48C110.635 158.62 113.147 163.556 115.289 168.225C115.605 168.921 114.615 169.525 114.139 168.933L114.153 168.935Z" fill="white"/>
      <path d="M70.4793 105.806L112.179 72.2253C114.644 70.2404 115.033 66.6333 113.048 64.1685L86.3662 31.0363C84.3814 28.5716 80.7743 28.1825 78.3095 30.1674L36.6099 63.7483C34.1452 65.7332 33.7562 69.3403 35.741 71.805L62.4226 104.937C64.4075 107.402 68.0146 107.791 70.4793 105.806Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_71_140">
        <rect width="77" height="202" fill="white" transform="translate(211.006 176.244) rotate(141.155)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'PickupIcon2d',
  props: {
    rotation: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: '#222E6C',
    },
  },
  computed: {
    getTransform() {
      const baseRotation = this.rotation;
      const flip = false;
      return flip ? `rotate(${baseRotation}deg) scaleX(-1)` : `rotate(${baseRotation}deg)`;
    },
  },
}
</script>