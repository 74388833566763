
<template>
    <div>
      <HeaderMobile />
      
      <div class="box-1" v-if="screenStep === 1">
        <v-row align="center" justify="center">
          <v-col>
            <span class="title-gateway">Gateway</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field id="plate" placeholder="Número do Gateway" v-on:keyup="handleClearGateway" append-icon-color="primary"
              v-model="dataset.filter.gateway" hide-details outlined>
              <template v-slot:append-outer>
                <v-btn @click="selectGateway" style="margin-top: -17px;" :height="56" color="secondary" elevation="0">OK</v-btn>
              </template>
            </v-text-field>
            <span class="example-text">Exemplo: b0fd0b7004730000</span>
          </v-col>
        </v-row>
  
        <v-row v-if="dataset.gatewaySelected">
          <v-col>
            <v-card v-if="!dataset.loading" class="gateway-card px-4" outlined>
              <v-card-text>
                <v-row align="center" justify="space-between" no-gutters>
                  <v-col cols="1">
                    <v-icon size="30" color="important">mdi-broadcast</v-icon>
                  </v-col>
                  <v-col cols="8">
                    <div class="gateway-card-title">
                      <span>{{ dataset.gatewaySelected }}</span>
                    </div>
                    <div class="gateway-card-subtitle">gateway digitado</div>
                  </v-col>
                  <v-col cols="1">
                    <v-btn icon @click="removeGateway">
                      <v-icon>mdi-delete-forever-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <div class="box-2" v-if="screenStep === 2">
        <v-row class="mt-2" align="center" justify="center">
            <v-col>
                <span class="title-gateway">Veículo</span>
            </v-col>
        </v-row>

        <v-row>
          <v-col>
              <v-text-field id="plate" placeholder="Placa ou Prefixo" append-icon="mdi-magnify"
                  append-icon-color="primary" v-model="dataset.filter.info" v-on:keyup="debounce" hide-details outlined></v-text-field>
          </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-text-field id="plate" placeholder="Número de Série" append-icon="mdi-magnify"
                    append-icon-color="primary" v-model="dataset.filter.serial" v-on:keyup="debounce" hide-details outlined></v-text-field>
            </v-col>
        </v-row>

        <v-row v-if="dataset.loading">
          <v-col>
            <template>
              <v-progress-linear indeterminate color="#222D6C"></v-progress-linear>
            </template>
          </v-col>
        </v-row>

        <v-row class="info-not-vehicle" v-if="!dataset.loading && dataset.notVehicle">
            <v-col>
              <template>
                <v-alert type="error" dismissible icon="mdi-broadcast-off">
                  <span>{{ dataset.infoNotVehicle }}</span>
                </v-alert>
              </template>
            </v-col>
          </v-row>

        <v-row class="custom-row-cards">
          <v-col>
              <v-card class="vehicle-card px-2 my-2" outlined v-for="(item, index) in dataset.filteredVehicles " v-bind:key="index">
                  <v-card-text>
                      <v-row align="center">
                          <v-col cols="2" sm="2">
                              <component :class="item.icon == 7 ? 'icon-size-truck-II' : 'icon-size-II'" :is="getIconComponent(item)" :color="ColorUtils.getColor(item)"></component>
                          </v-col>
                          <v-col cols="8" sm="7">
                              <v-row no-gutters>
                                  <v-col>
                                      <div 
                                          class="vehicle-card-title"
                                          :style="{ color: ColorUtils.setColorText(item.equipment.model.type, item.equipment.status)}"
                                      >
                                          <span>
                                              {{ item.plate }}
                                          </span>
                                      </div>
                                  </v-col>
                              </v-row>
                              <v-row no-gutters>
                                  <v-col>
                                      <div class="vehicle-card-subtitle">{{ item.client.name }}
                                      </div>
                                  </v-col>
                              </v-row>
                          </v-col>
                          <v-col cols="1" align="right">
                              <v-btn icon @click="removeVehicle"><v-icon>mdi-delete-forever-outline</v-icon></v-btn>
                          </v-col>
                      </v-row>
                  </v-card-text>
              </v-card>
            </v-col>
        </v-row>
      </div>
    
  
      <v-bottom-sheet v-model="sheet" hide-overlay>
        <v-card class="custom-bottom-sheet">
          <v-card-title class="headline">Atenção Antenista!</v-card-title>
          <v-card-text>
            Você precisa permitir que a plataforma acesse sua localização para que você seja mostrado no mapa.
            <br /> <br/>
            Se você não permitir, aparecerá apenas a localização do veículo que está sendo buscado.
          </v-card-text>
          <v-card-actions>
            <v-btn @click="sheet = false" :height="56" width="100%" color="secondary" elevation="0">OK, Entendi</v-btn>
          </v-card-actions>
        </v-card>
      </v-bottom-sheet>
  
      <v-row class="button-container" align="center" justify="center">
        <v-col cols="12">
          <v-btn 
            @click="screenStep == 1 ? nextStep() : goTracking()" 
            :height="56" 
            color="secondary" 
            elevation="0"
          >
            {{ screenStep == 1 ? 'Avançar' : 'Encontrar Veículo' }}
          </v-btn>
        </v-col>
        <v-col v-if="screenStep === 2">
          <v-btn @click="prevStep" :height="56" color="secondary" outlined elevation="0">Voltar</v-btn>
        </v-col>
      </v-row>

 
    </div>
  </template>


  <script>
  import Vue from "vue";
  import _, { remove } from 'lodash';
  import axios from 'axios';
  import VueLodash from 'vue-lodash'
  import object from "@/common/util/object"
  import HeaderMobile from "@/layouts/components/HeaderMobile";

  import vehicleIcons from "@/common/data/vehicle-icons.data"

  import CarIcon from '@/components/icons/CarIcon2d.vue';
  import BusIcon from '@/components/icons/BusIcon2d.vue';
  import HatchIcon from '@/components/icons/HatchIcon2d.vue';
  import PickupIcon from '@/components/icons/PickupIcon2d.vue';
  import TruckIcon from '@/components/icons/TrcukIcon2d.vue';
  import VanIcon from '@/components/icons/VanIcon.vue';
  import MotorcycleIcon from '@/components/icons/MotorcycleIcon2d.vue';
  import CartIcon from "@/components/icons/CartIcon.vue";
  import HorseIcon from "@/components/icons/HorseIcon.vue";

  import ColorUtils from "@/common/util/ColorUtils";
  
  Vue.use(VueLodash)

  export default {
    name: 'Gateway',
    data() {
      return {
        ColorUtils,
        screenStep: 1,
        vehicles: [],   
        dataset: {
          filter: {
            gateway: '',
            info: null,
            serial: null,
          },
          loading: false,
          notVehicle: false,
          filteredVehicles: [],
          gatewaySelected: null,
          vehicleIcons: vehicleIcons,
          infoNotVehicle: 'Veículo não encontrado',
        },
        sheet: true,
      };
    },
    components: {
      HeaderMobile
    },
    methods: {
      debounce: _.debounce(function() {
          this.getVehicle();
      }, 500),

        
      async selectGateway() {            
          if(!this.dataset.filter.gateway) {
              return;
          }

          localStorage.setItem('gateway', this.dataset.filter.gateway);
          this.dataset.gatewaySelected = this.dataset.filter.gateway;

          this.$emit('update-filter-gateway', this.dataset.filter.gateway);
      },

      async getVehicle() {
            let search = Object.fromEntries(Object.entries(this.dataset.filter).filter(([_, v]) => v))

            search = !object.isEmpty(search) ? search : null;

            if(!search.info && !search.serial) {
                return;
            }
            
            this.dataset.loading = true;

            if(search && search.gateway) {
                delete search.gateway;
            }
            
            try {
                const result = await axios.get('/vehicles/broadcaster', { params: { start: 0, limit: 10000, search: search } });

                if(result.data.data.length > 0) {
                    this.dataset.notVehicle = false;
                    this.dataset.filteredVehicles = result.data.data;
                    localStorage.setItem('vehicles', JSON.stringify(result.data.data));
                    this.resolveVehicles();

                    this.$emit('update-filter-info', search.info);
                    this.$emit('update-filter-serial', search.serial);
                    // this.$emit('update-vehicles', result.data.data);
                } else {
                    this.dataset.notVehicle = true;
                    this.dataset.filteredVehicles = null;
                }
                
            } catch (error) {
                console.log('error ', error)
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataset.loading = false;
            }
        },

      filterVehicles() {

        let search = Object.fromEntries(Object.entries(this.dataset.filter).filter(([_, v]) => v))

        this.dataset.filter.info = search.info;
        this.dataset.filter.serial = search.serial;

        this.dataset.filteredVehicles = this.vehicles.filter(vehicle => {
            return vehicle.plate == this.dataset.filter.info || vehicle.prefix == this.dataset.filter.info || vehicle.equipment.serial == this.dataset.filter.serial;
        });

        this.resolveVehicles();
      },

      resolveVehicles() {
            for (let item of this.dataset.filteredVehicles) {
                const icon = this.getAssetsByIcon(item.icon);

                item.customIcon = icon;
            }
        },

      removeGateway() {
        // this.dataset.filter.info = null;
        // this.dataset.filter.serial = null;
        this.dataset.filter.gateway = null;
        this.dataset.gatewaySelected = null;
      },

      removeVehicle() {
        this.dataset.filter.info = null;
        this.dataset.filter.serial = null;
        this.dataset.filteredVehicles = [];
      },
  
      handleClearGateway() {
        if(this.dataset.filter.gateway == '') {
          this.removeGateway();
          this.dataset.notVehicle = false;
        }
      },
  
      nextStep() {
        if(!this.dataset.gatewaySelected) {
            this.$dialog.notify.info('Informe o número do Gateway', { position: 'top-right', timeout: 5000 });
            return;
        }
        this.screenStep = 2;
      },

      prevStep() {
        this.dataset.filter.info = null;
        this.dataset.filter.serial = null;
        this.dataset.filteredVehicles = [];
        this.screenStep = 1;
      },

      goTracking() {
        if(!this.dataset.filteredVehicles.length) {
          this.$dialog.notify.info('Informe o veículo', { position: 'top-right', timeout: 5000 });
          return;
        }

        if(this.dataset.filter.info){
          localStorage.setItem('info', this.dataset.filter.info);
        }

        if(this.dataset.filter.serial){
          localStorage.setItem('serial', this.dataset.filter.serial);
        }

        if(this.dataset.filter.gateway){
          localStorage.setItem('gateway', this.dataset.filter.gateway);
        }
        
        this.$router.push({ 
          path: '/tracking/3',
        });
      },
      getIconComponent(vehicle) {
          const iconComponents = {
              'car.svg': CarIcon,
              'bus.svg': BusIcon,
              'hatch.svg': HatchIcon,
              'motorcycle.svg': MotorcycleIcon,
              'pickup.svg': PickupIcon,
              'truck.svg': TruckIcon,
              'van.svg': VanIcon,
              'cart.svg': CartIcon,
              'horse.svg': HorseIcon,
          };
      return iconComponents[vehicle.customIcon] || null;
      },

        
      getAssetsByIcon(icon) {
          return this.dataset.vehicleIcons.find((x) => x.id === icon).assets
      },

    }
  }
  </script>
  
  <style lang="scss">
  .box-1, .box-2 {
    padding: 40px;
    min-height: 600px;
  }
  
  .title-gateway{
    position: relative;
    top: 10px;
    color: var(--v-primary-base);
    font-family: Montserrat !important;
    font-size: 45px !important;
    font-style: normal !important;
    font-weight: 800 !important;
    text-transform: none;
  }
  
  .example-text {
    display: block;
    font-size: 12px;
    color: gray;
  }
  
  .custom-bottom-sheet {
    padding: 40px;
    font-family: Montserrat !important;
  }
  
  .headline {
    font-weight: 600 !important;
    text-transform: none !important;
    font-family: Montserrat !important;
    color: var(--v-primary-base);
  }
  
  .gateway-card {
    margin-top: -5px !important;

    .v-card__text {
      padding: 20px 5px !important;
    }
    .gateway-card-title {
      color: var(--v-primary-base);
      font-family: Montserrat;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
    }
  
    .gateway-card-subtitle {
      color: var(--v-mediumGray-base);
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      margin-top: -5px;
      font-weight: 500;
    }
  
    transition: ease-in-out 0.4s;
  }

  .vehicle-card {
    .v-card__text {
      padding: 20px 5px !important;
    }
    .vehicle-card-title {
        color: var(--v-primary-base);
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
    }

    .vehicle-card-subtitle {
        color: var(--v-mediumGray-base);
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
    }
    transition: ease-in-out 0.4s;
}

  
  .button-container {
    position: fixed;
    bottom: 0;
    left: 50%;
    margin: 0 !important;
    transform: translateX(-50%);
    width: 100%;
    padding: 30px;
    background-color: white;
  }

  .button-container .v-btn {
    width: 100%;
  }

  .info-not-vehicle svg{
    margin-right: 10px;
  }

  .custom-row-cards {
    margin-bottom: 200px;
  }
  </style>