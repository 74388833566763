<template>
    <v-navigation-drawer v-model="dataset.opened" absolute bottom temporary right hide-overlay stateless :width="440">
        <div class="px-6">
            <v-row class="mt-2" align="center" justify="center">
                <v-col>
                    <span class="list-vehicle-title">Gateway</span>
                </v-col>
                <v-spacer></v-spacer>
                <v-col align="right" class="mt-2">
                    <v-btn icon elevation="0" @click="dataset.opened = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-col>
            </v-row>
            <v-row class="container-filters">
                <v-col>
                    <v-text-field id="plate" label="Digite o número do gateway" placeholder="Digite o número do gateway" append-icon-color="primary"
                        v-model="dataset.filter.gateway" hide-details outlined v-on:keyup="handleClearGateway">
                        <template v-slot:append-outer>
                            <v-btn @click="selectGateway" style="margin-top: -17px;" :height="56" color="secondary"
                                elevation="0">OK</v-btn>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row v-if="dataset.gatewaySelected">
                <v-col>
                    <v-card class="gateway-card px-2" outlined>
                        <v-card-text>
                            <v-row align="center">
                                <v-col cols="12" sm="2">
                                    <v-icon size="30" color="important">mdi-broadcast</v-icon>
                                </v-col>
                                <v-col cols="12" sm="7">
                                    <v-row no-gutters>
                                        <v-col>
                                            <div class="gateway-card-title">
                                                <span>
                                                    {{ dataset.gatewaySelected }}
                                                </span>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col>
                                            <div class="gateway-card-subtitle">gateway digitado
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col align="right">
                                    <v-btn icon @click="removeGateway"><v-icon> mdi-delete-forever-outline</v-icon></v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row class="mt-2" align="center" justify="center">
                <v-col>
                    <span class="list-vehicle-title">Veículo</span>
                </v-col>
            </v-row>
            <v-row class="container-filters">
                <v-col>
                    <v-text-field id="plate" label="Placa ou Prefixo" placeholder="Placa ou Prefixo"
                        v-model="dataset.filter.info" v-on:keyup="debounce" hide-details outlined 
                        :disabled="dataset.gatewaySelected ? false : true">
                        <template v-slot:append>
                            <v-icon v-if="dataset.filter.info != null" @click="handleClearInfo" class="mr-2">mdi-close</v-icon>
                            <v-icon v-else class="mr-2">mdi-magnify</v-icon>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row class="container-filters">
                <v-col>
                    <v-text-field id="serial" label="Número de Série" placeholder="Número de Série"
                        v-model="dataset.filter.serial" v-on:keyup="debounce" hide-details outlined 
                        :disabled="dataset.gatewaySelected ? false : true">
                        <template v-slot:append>
                            <v-icon v-if="dataset.filter.serial != null" @click="handleClearSerial" class="mr-2">mdi-close</v-icon>
                            <v-icon v-else class="mr-2">mdi-magnify</v-icon>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row v-if="dataset.loading">
                <v-col>
                    <template >
                        <v-progress-linear indeterminate color="#222D6C"></v-progress-linear>
                    </template>
                </v-col>
            </v-row>
            <v-row class="info-not-vehicle" v-if="!dataset.loading && dataset.notVehicle">
                <v-col>
                    <template >
                    <v-alert type="error" dismissible icon="mdi-broadcast-off">
                        <span>{{ dataset.infoNotVehicle }}</span>
                    </v-alert>
                    </template>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-card class="vehicle-card px-2 my-2" outlined v-for="(item, index) in dataset.filteredVehicles " v-bind:key="index" @click="open(item)">
                        <v-card-text>
                            <v-row align="center">
                                <v-col cols="12" sm="2">
                                    <component :class="item.icon == 7 ? 'icon-size-truck-III' : 'icon-size-III'" :is="getIconComponent(item)" :color="ColorUtils.getColor(item)"></component>
                                </v-col>
                                <v-col cols="12" sm="5">
                                    <v-row no-gutters>
                                        <v-col>
                                            <div 
                                                class="vehicle-card-title"
                                                :style="{ color: ColorUtils.setColorText(item.equipment.model.type, item.equipment.status)}"
                                            >
                                                <span>
                                                    {{ item.plate }}
                                                </span>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col>
                                            <div class="vehicle-card-subtitle">{{ item.client.name }}
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="1" sm="5" class="custom-col-flag">
                                    <div :class="ColorUtils.getTemplateFlag(item.equipment.model.type, item.equipment.status)">
                                        <span>{{ getEquipmentTypeName(item.equipment.model.type) }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </v-navigation-drawer>
</template>

<script>

import Vue from "vue";
import _ from 'lodash';
import axios from 'axios';
import { eventHub } from '@/main'
import VueLodash from 'vue-lodash'
import object from "@/common/util/object"
import vehicleIcons from "@/common/data/vehicle-icons.data"

import CarIcon from '@/components/icons/CarIcon2d.vue';
import BusIcon from '@/components/icons/BusIcon2d.vue';
import HatchIcon from '@/components/icons/HatchIcon2d.vue';
import PickupIcon from '@/components/icons/PickupIcon2d.vue';
import TruckIcon from '@/components/icons/TrcukIcon2d.vue';
import VanIcon from '@/components/icons/VanIcon.vue';
import MotorcycleIcon from '@/components/icons/MotorcycleIcon2d.vue';
import CartIcon from "@/components/icons/CartIcon.vue";
import HorseIcon from "@/components/icons/HorseIcon.vue";

import ColorUtils from "@/common/util/ColorUtils";

Vue.use(VueLodash)

export default {
    name: "VehicleNearbyFilter",

    props: {
        vehicles: Array,
        openInfoWindow: Function
    },
    data: () => ({
        ColorUtils,
        dataset: {
            opened: false,
            loading: false,
            notGateway: false,
            infoNotVehicle: 'Veículo não encontrado',
            vehicleIcons: vehicleIcons,
            gatewaySelected: null,
            filter: {},
            status: [],
            events: [],
            filteredVehicles: [],
        }
    }),

    created() {
        // this.dataset.filteredVehicles = this.vehicles;

        this.resolveVehicles();
        eventHub.$on("VEHICLE-NEARBY-FILTER-CHANGE", (value) => {
            this.dataset.opened = value;
        });
    },

    methods: {
        debounce: _.debounce(function() {
            this.$emit('update-vehicles', []);
            this.getVehicle();
        }, 800),
        
        async selectGateway() {            
            if(!this.dataset.filter.gateway) {
                return;
            }

            localStorage.setItem('gateway', this.dataset.filter.gateway);
            this.dataset.gatewaySelected = this.dataset.filter.gateway;

            this.$emit('update-filter-gateway', this.dataset.filter.gateway);
        },

        async getVehicle() {
            let search = Object.fromEntries(Object.entries(this.dataset.filter).filter(([_, v]) => v))

            search = !object.isEmpty(search) ? search : null;

            if(!search.info && !search.serial) {
                return;
            }
            
            this.dataset.loading = true;

            if(search && search.gateway) {
                delete search.gateway;
            }

            
            
            try {
                const result = await axios.get('/vehicles/broadcaster', { params: { start: 0, limit: 10000, search: search } });

                if(result.data.data.length > 0) {
                    this.dataset.notVehicle = false;
                    this.dataset.filteredVehicles = result.data.data;
                    this.resolveVehicles();

                    this.$emit('update-filter-info', search.info);
                    this.$emit('update-filter-serial', search.serial);
                    // this.$emit('update-vehicles', result.data.data);
                } else {
                    this.dataset.notVehicle = true;
                    this.dataset.filteredVehicles = null;
                }
                
            } catch (error) {
                console.log('error ', error)
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataset.loading = false;
            }
        },

        open(item) {
            this.openInfoWindow(item);
        },

        resolveVehicles() {
            for (let item of this.dataset.filteredVehicles) {
                const icon = this.getAssetsByIcon(item.icon);
                // const svg = this.$image.customSvgColor(`!!raw-loader!@/assets/images/vehicle/icons/${icon}`, '[carcolor]', '#FB3A04')

                item.customIcon = icon;
            }
        },

        getIconComponent(vehicle) {
            const iconComponents = {
                'car.svg': CarIcon,
                'bus.svg': BusIcon,
                'hatch.svg': HatchIcon,
                'motorcycle.svg': MotorcycleIcon,
                'pickup.svg': PickupIcon,
                'truck.svg': TruckIcon,
                'van.svg': VanIcon,
                'cart.svg': CartIcon,
                'horse.svg': HorseIcon,
            };
        return iconComponents[vehicle.customIcon] || null;
        },

        getAssetsByIcon(icon) {
            return this.dataset.vehicleIcons.find((x) => x.id === icon).assets
        },

        removeGateway() {
            localStorage.removeItem('gateway');

            this.dataset.filter.gateway = null;
            this.dataset.gatewaySelected = null;

            this.$emit('update-filter-gateway', null);

        },

        handleClearSerial() {
            this.dataset.filter.serial = null;
            this.$emit('update-filter-info', null);
            this.$emit('update-filter-serial', null);

            this.dataset.filteredVehicles = null;
            this.$emit('update-vehicles', []);
        },

        handleClearInfo() {
            this.dataset.filter.info = null;
            this.$emit('update-filter-info', null);
            this.$emit('update-filter-serial', null);

            this.dataset.filteredVehicles = null;
            this.$emit('update-vehicles', []);
        },

        handleClearGateway() {
            if(this.dataset.filter.gateway == '') {
                this.removeGateway();
                this.dataset.notVehicle = false;
            }
        },

        getEquipmentTypeName(type) {
            if(type == 5){
                return 'Mac Lora'
            }

                if(type == 4){
                    return 'Mac 4g'
            }
        },
    },
};
</script>

<style lang="scss">

.theme--light.v-input input, .theme--light.v-input textarea, .v-select__selection--comma  {
    color: #222D6C !important;
    font-weight: 500 !important;
}
.card-safe-area-infobox {

    .title-label {
        color: var(--v-secondary-base) !important;
        font-family: Montserrat;
        font-size: 28px;
        font-style: normal;
        text-transform: uppercase;
        font-weight: 700;
    }

    .subtitle-label {
        color: var(--v-primary-base);
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
    }

    .desc-label {
        color: var(--v-mediumGray-base);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        /* 380% */
    }

    .add-info-box {
        background: var(--v-secondary-base) !important;
        width: 45px;
        height: 45px;
    }

}

.vehicle-card {
    .vehicle-card-title {
        color: var(--v-primary-base);
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
    }

    .vehicle-card-subtitle {
        color: var(--v-mediumGray-base);
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
    }
    transition: ease-in-out 0.4s;
}

.info-not-vehicle{
    color: var(--v-important-base) !important;
}


.gateway-card {
    margin-top: -20px !important;
    .gateway-card-title {
        color: var(--v-primary-base);
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
    }

    .gateway-card-subtitle {
        color: var(--v-mediumGray-base);
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
    }

    transition: ease-in-out 0.4s;
}

.gateway-card:hover,
.vehicle-card:hover {
    border-color: var(--v-secondary-base) !important;
}

.icon-size-truck-III{
    position: relative;
    left: -5px;
    width: 50px !important;
}

.icon-size-III{
    position: relative;
    top: 5px;
    left: -3px;
    width: 70px !important;
}

.list-vehicle-title{
    font-size: 26px !important;
    font-weight: 800 !important;
}

.container-filters{
    margin-top: -15px !important;
}

.custom-col-flag{
    display: flex;
    justify-content: flex-end !important;
}

.equipament-blue-I,
.equipament-red-I,
.equipament-yellow  {
    padding: 5px;
    width: 100% !important;
    border-radius: 5px;
    display: flex;  
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    justify-content: center;
}

.equipament-blue-I{
    color: var(--v-primary-base);
    background-color: rgba(34, 45, 108, 0.07);
}

.equipament-red-I{
    color: var(--v-important-base);
    background-color: rgba(251, 58, 4, 0.07);
}

.equipament-yellow{
    color: var(--v-yellow-base);
    background-color: rgba(255, 183, 3, 0.07);
}
</style>